
<template>


<div class="content">
  <div class="bannerView">
      <img src="../assets/aboutBanner.jpg" class = 'banner'>
  </div>
  <div style="display: flex; align-items:center;justify-content: center;font-size: 6vh;height: 10vh;margin-top: 2%;">
      <span>公司介绍</span>
  </div>
  <div class="middleView">
     <span>公司核心国队拥有15年的酒店餐饮信息化经验和技术背景， 深耕于酒店及餐饮管理软件的研发、推广及服务，日前己为近6000家酒店餐饮企业完成了 信息化建设，被评力酒店业最佳软件供应商。
      <br>
随者互联网技术在传统行业的运用，餐饮业对数据化、信息化、平台化的要求越来越高。公司以云计算力研发方向．致力开开发大食意餐饮云平台。产品运用目前国内最前沿的SAAS云技术，开放的平台架构，打造集软件应用、集团管理、供应链管理：人才招聘：人工智能为一体的食生态
圈的平台。
<br>
未来公司
服务业为主要发展方向，结合自身十余年积累的资源和优势，致力成为中国最具影响力的应用管理型SAAS软件平台服务商，号!领行业发展，推动社会进龙！</span>
  </div>
  <div class="greyView">
    <div class="greyTopView">
      <span>品牌理念</span>
    </div>
    <div class="greyBottomView">
      <span style="margin-bottom: 1%;">企业愿景:共建和谐环境；传承人本教育；创造百年企业</span>
      
      <span style="margin-bottom: 1%;">企业精神:热情、开放、责任、奉献</span>
      
      <span style="margin-bottom: 1%;">企业理念:创新、和谐、诚信共赢</span>
    </div>
  </div>
  <div class="bottomView">
    <div class="bottomImageView">
      <img class="ImageStyle" src="../assets/aboutBottom1.jpg">
    </div>
    <div class="bottomImageView">
      <img class="ImageStyle" src="../assets/aboutBottom2.jpg">
    </div>
  </div>
</div>
</template>


<script>


export default {


  name: 'About'


}


</script>
<style lang="less">
 .content{
    display: flex;
    height: 100vw; 
    flex-direction: column;
    .bannerView{
    display: flex;
    width: 100vw;
    height: 25%;
      .banner{
      display: flex;
      width: 100%;
      height: 100%;
      }
    }
    .middleView{
    width: 80vw;
    height: auto;
    text-align: center;
    align-self: center;
    word-wrap: break-word;
    font-size: 1.5vw;
  }
  .greyView{
    display: flex;
    width: 100vw;
    margin-top: 5%;
    height: auto;
    background-color: #f2f2f2;
    flex-direction: column;
    .greyTopView{
      font-size: 2.5vw;
      display: flex;
      margin-left: 4%;
      margin-top: 4%;
      margin-bottom: .5%;
    }
    .greyBottomView{
      display: flex;
      margin-left: 4%;
      flex-direction: column;
      font-size: 1.8vw;
      justify-content: center;
      margin-top: 2%;
      color: #101010;
      
    }
  }
  }
  
 
  
  
  .bottomView{
      height: 23%;
      width: 100vw;
      margin-top: 2%;
      display: flex;
      justify-content: space-between;
      .bottomImageView{
        flex: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ImageStyle{
        width: 80%;
        height: 100%;
      }

    }
</style>