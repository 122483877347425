import Vue from 'vue';
import Router from 'vue-router'
import Home from '../views/Home'
import About from '../views/About'
import Dsweet from '../views/Dsweet'
import Tianshine from '../views/Tianshine.vue'
Vue.use(Router)

export default new Router({
    mode:'history',
    routes:[
        {
            path:'/',
            name:'home',
            component:Home,
            
        },

        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path:'/tianshine',
            name:'tianshine',
            component:Tianshine
        },
        {
            path:'/dsweet',
            name:'dsweet',
            component:Dsweet
        }
    ]
})