
<template>


  <div class="content">
    <div class="bannerView">
      <img src="../assets/banner.jpg" class = 'banner'>
      
    </div>
    <div class="middleView" :style="{fontSize:textMidle+'vh'}">
      <span>产品介绍</span>
    </div>
    <div class="bannerView" style="height: auto;">
      <div class="leftView">
        <div class="leftTopView">
          <span>
            天食安
          </span>
          <span>
            食品安全智能监管平台
          </span>
        </div>
        <div class="leftBottmView" :style="{fontSize:textH+'vh'}">
          <span>
              使用Al识别技术，
            <br>
              通过机器视觉＋物联网视觉检测技术等手段，
            <br>
              对厨房环境及食品储存加工区域实行智能化改造，
            <br>
              根据食品安全法条例规定，规范所有流程，
            <br>
              使得整套操作清晰明了，
            <br>
              通过A实时感测云中心，对食品环境进行实时检测，
            <br>
              建立多层级事先预警管理
            <br>
          </span>
            
        </div>
        <button :style="{fontSize:buttonText+'px'}" v-on:click="goDetial('tianshine')" type="button">了解更多</button>
      </div>
      <div class="rightView">
        <img style="width: 100%;height: 100%;" src="../assets/tianshineProduct.jpg">
      </div>
    </div>
    <div style="width: 100vw;height: 5vh;background-color: yellow;"/>
    <div class="bannerView" style="margin-top: 2vh;height: auto;" >
      <div class="rightView">
        <img style="width: 100%;height: 100%;" src="../assets/dweetProduct.jpg">
      </div>
      <div class="leftView">
        <div class="leftTopView" style="color: #026eb7;">
          <span>
            大食慧
          </span>
          <span>
            大食慧智能餐饮平台
          </span>
        </div>
        <div class="leftBottmView" :style="{fontSize:textH+'vh'}">
            <span>
            大食慧软件通过15年的餐饮行业信息化经验，总结了餐企
           
          <br>
            "门店运营一营销导流十成本管控一决策分析“
          <br>
            四大管理要点，
          <br>
            基于自主研发的门店，会员，供应链集团四条业务线产品，

          <br>
            通过深度行业化的定制，
          <br>
            打造SaaS云端的前，中，后一体化智慧餐饮解决方案，
          <br>
            全面支撑餐企多业态和多样化行业管理需求</span>
        </div>
        <button :style="{fontSize:buttonText+'px'}" v-on:click="goDetial('dsweet')" type="button">了解更多</button>
      </div>
      
    </div>
    <div class="middleView" :style="{fontSize:textMidle+'vh'}">
      <span>公司简介</span>
    </div>
    <div  :style="{fontSize:textH+'vh'}" style="text-align: center;">
      安徽碧耕公司是集软件研发、人工智能应用于一体的国家级高新技术企业，
      <br>
      是合肥市高新区重点扶持的维鹰企业和科创板挂牌企业，
      <br>
      拥有 2项专利，自主知识产权 70余项。
      <br>
      公司CTO联合创始人、产品架构师胡二琳带领研发团队，
      <br>
      核心成员来自中科大，拥有近 20 年的餐饮行业信息化经验，
      <br>
      自主开发“大食慧”智慧餐饮管理软件和“天食安”智慧食品安全监管云平台。

      <br>
      “大食慧”团队先后参与过 8000 多家餐饮企业信息化建设。
      <br>
      天食安”云平台系统在全国已上线277个项目，
      <br>
      包含政府机关幼儿园 37所、小学 41 所、中学 45所、大学 22 所、
      <br>
      企业园区 60 家、团餐企业及事业单位 57 家、中央工厂 17 家、医院 4 家
      <br>
    </div>
    <div style="flex-direction: row;display: flex;margin-top: 1.5vh;">
      <img src="../assets/homeIcon.jpg" style="display: flex;width: 2vw;height: 2vw;">
      <span style="margin-left: 1.5vw;font-size: 2vw;">荣誉资质</span>
    </div>
    <div class="certView">
      <img src="../assets/homeCert.jpg" class="certClass">
    </div>
   
    <div class="middleView" :style="{fontSize:textMidle+'vh'}">
      <span>现开启全国招商，欢迎咨询</span>
    </div>
    <div style="display: flex;flex-direction: row;height: 20vh;">
      <div class="bottomItemView" :style="{fontSize:textBottom+'vh'}">
        <img src="../assets/homeIcon1.jpg" class="bottomIcon">
        <span style="color: #026eb7;">公司地址</span>
        <span>安徽省合肥市高新区同创科技园5栋5楼</span>
      </div>
      <div class="bottomItemView" :style="{fontSize:textBottom+'vh'}">
        <img src="../assets/homeIcon2.jpg" class="bottomIcon">
        <span style="color: #026eb7;">联系电话</span>
        <span>0551-63671869</span>
      </div>
      <div class="bottomItemView" :style="{fontSize:textBottom+'vh'}">
        <img src="../assets/homeIcon3.jpg" class="bottomIcon">
        <span style="color: #026eb7;">邮箱</span>
        <span>2645053571@qq.com</span>
      </div>
    </div>
  </div>


</template>


<script>


export default {      
    name: 'Home',
    data(){
      return{
        plat:'PC',
        textH:3,
        buttonText:16,
        textMidle:5,
        textBottom:2
      }
    },
    mounted(){
      if (this.isMobile()) {
        this.plat = "mobile"
        this.textH = 1;
        this.buttonText = 6;
        this.textMidle = 3;
        this.textBottom = 1.5;
        // 移动端逻辑
      } else {
        this.plat = "PC"
        this.textH = 3
       
    // PC端逻辑
      }
    },
   
    methods:{
      goDetial(status){
        this.$router.push('/'+status, ()=>{}, ()=>{})  
      },
      isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
      }
    }
}


</script>
<style lang="less">
  .content{
    display: flex;
    height: 100vh; 
    padding-bottom: 0%;
    width: 100%;
    flex-direction: column;
    
    .bannerView{
    display: flex;
    width: 100%;
    height: 30%;
    
    
    .leftView{
    display:flex;
    height: auto;
   
    align-items: center;
    flex-direction: column;
      .leftTopView{
        width: 100%;
        
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-size: 1.5vw;
        color: #567722;
      }
      .leftBottmView{

        height: auto;
        width: 100%;
        text-align: center;
        justify-content: center;
      
       
      }
    } 
    .middleView{
    display: flex; 
    align-items:center;
    justify-content: center;
    font-size: 10vh;
    height: 20vh;
    margin-top: 5%;
   
  }
    
  }
  }

  
  .banner{
    display: flex;
    width: 100%;
    height: 100%;
  
  }

  
  .rightView{
    display: flex;
    flex: 1;
    padding: 2vw;
    
}

.bottomItemView{
      margin-top: 2vh;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-color: #3894ff;
      text-align: center;
      
    }
button{
  background-color:none; 
  border-color: #3894ff;
  color: #3894ff;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top:8px;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  border-radius: 5px; /* 圆角边框 */
  height: 20%;
  width: 30%;
}
.certView{
  display: flex;
  justify-content: center;
  align-items: center;
 
  .certClass{
    width: 80vw;
    height: auto;
  }
  
}
.bottomIcon{
  width: 3vw;
  height: 3vw;
}
</style>