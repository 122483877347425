<template>
  <div class="content">
    <div class="bannerView" style="height: auto;">
      <img
        style="width: 100%; height: 100%"
        src="../assets/dsweet/dsweet1.png"
      />
    </div>
  
    <div class="bannerView" style="height: auto">
      <img
        style="width: 100%; height: 100%"
        src="../assets/dsweet/dsweet7.png"
      />
      <!-- <div class="middleView">
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon1.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div style="color: #0077ff;" class="contentText">断网收银</div>
            <div class="contentText">本地数据库部署，断网可收银，联网后数据同步备份</div>
          </div>
        </div>
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon2.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div style="color: #0077ff;" class="contentText">多系统支撑</div>
            <div class="contentText">多平台支擇，满足顾客个性化需求</div>
          </div>
        </div>
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon3.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div style="color: #0077ff;" class="contentText">外卖对接</div>
            <div class="contentText">美团，饿了么外卖平台完美对接，自动接单</div>
          </div>
        </div>
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon4.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div  style="color: #0077ff;" class="contentText">KDS厨显</div>
            <div class="contentText">KDS厨房显示屏出单自动显示，节省人力，减少人工控菜出错率</div>
          </div>
        </div>
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon5.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div style="color: #0077ff;" class="contentText">移动收银</div>
            <div class="contentText">服务员手机安装程序 移动服务和收银，提升全员服务意识</div>
          </div>
        </div>
        
      </div>
      <div class="middleView">
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon6.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div style="color: #0077ff;" class="contentText">自助点餐</div>
            <div class="contentText">多场景自助点餐模式，降低人力成本，提升消费体验</div>
          </div>
        </div>
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon7.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div style="color: #0077ff;" class="contentText">数据互通</div>
            <div class="contentText">收银、会员．库存多系統数据互通，全面管控您的门店</div>
          </div>
        </div>
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon8.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div style="color: #0077ff;" class="contentText">小程序平台</div>
            <div class="contentText">自主小程序平台，无成本开新店，拓客引流</div>
          </div>
        </div>
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon9.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div style="color: #0077ff;" class="contentText">聚合支付</div>
            <div class="contentText">主流支付方式全聚合，折扣权限安全可控</div>
          </div>
        </div>
        <div class="itemView">
          <div class="iconView">
            <img
              style="width: 3vw; height: 3vw"
              src="../assets/dsweet/icon/icon10.jpg"
            />
          </div>

          <div style="flex-direction: column; width: 80%; height: 100">
            <div style="color: #0077ff;"  class="contentText">手机巡店</div>
            <div class="contentText">营业数据实时汇总，随时随地手机查看一切尽在 "掌“握之中</div>
          </div>
        </div>
        
      </div> -->
    </div>
    <div class="bannerView" style="height: auto;">
      <img
        style="width: 100%; height: 100%"
        src="../assets/dsweet/dsweet3.png"
      />
    </div>
    
    <div class="bannerView" style="height: auto;">
      <img
        style="width: 100%; height: 100%"
        src="../assets/dsweet/dsweet4.png"
      />
    </div>
    <div class="textView">
      我们支持的餐饮类型
    </div>
    <div class="bannerView" style="height: auto;">
      <img
        style="width: 100%; height: 100%"
        src="../assets/dsweet/dsweet5.png"
      />
    </div>
    <div class="textView">
      我们的合作伙伴
    </div>
    <div class="bannerView" style="height: auto;">
      <img
        style="width: 100%; height: 100%"
        src="../assets/dsweet/dsweet6.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Dsweet",
};
</script>
<style lang="less">
.content {
  display: flex;
  height: 100vw;
  flex-direction: column;
}
.bannerView {
  display: flex;
  width: 100vw;
  height: 25%;
}
.middleView {
  flex-direction: column;
  display: flex;

  width: 100%;
  height: 100%;
  .itemView {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    //  background-color: aqua;
    align-items: center;
    justify-content: center;
    .iconView {
      display: flex;
      width: 20%;
      height: 100%;
     
      align-items: center;
      justify-content: center;
    }
    .titleText {
      display: flex;
      width: 100%;
      height: 50%;
    }
    .contentText{
      display: flex;
      width: 100%;
      height: auto;
    }
  }
}
.textView{
  margin: 5vh;

  text-align: center;
  font-size:4vh;
}
</style>
