<template>
  <div class="content">
    <div class="bannerView">
      <div class="leftView">
        <div class="leftBottmView" :style="{fontSize:leftText+'vh'}">
          为解决中小学、
          <br />
          幼儿园学生餐食材采购监管和财务监管等问题，
          <br />
          天食安“带量食谱＋营养餐管理系统”建立了供应商库、
          <br />
          统一的菜品库、菜品配方库，
          <br />
          根据周营养菜谱、 就餐人数，生成带量食谱；

          <br />
          再通过发改委定价体系、采购量，生成采购单，
          <br />
          最终实现教育部门对中小学、幼儿园“食品安全＋供应链”的实时监管及运营。
          <br />
          从源头把控资金和菜品安全
        </div>
      </div>
      <div class="rightView">
        <img
          style="width: 100%; height: 100%"
          src="../assets/tianshine/top1.jpg"
        />
      </div>
    </div>
    <div class="bannerView">
      <div class="rightView">
        <img
          style="width: 100%; height: 100%"
          src="../assets/tianshine/top2.jpg"
        />
      </div>
      <div class="leftView">
        <div class="leftBottmView" :style="{fontSize:leftText+'vh'}">
          建通过接入视频流，
          <br />
          用机器视觉分析算法分析后厨人员
          <br />
          末戴厨帽、玩手机、抽烟、未带口罩、鼠患活动等多种场景算法检测。

          <br />
          实时分析，抓拍违规照片，动态预警。
        </div>
      </div>
    </div>
    <div class="bannerView">
      <div class="leftView">
        <div class="leftBottmView" :style="{fontSize:leftText+'vh'}">
          引入了A视频分析仪、
          <br />
          智能电子秤、人脸识别打卡测温仪、智能留样柜等设备，
          <br />
          使现场操作更智能、更便捷、更高效。
          <br />
          同时，使管理者监管更清晰、更实时、更精准
          <br />
        </div>
      </div>
      <div class="rightView">
        <img
          style="width: 100%; height: 100%"
          src="../assets/tianshine/top5.jpg"
        />
      </div>
    </div>
    <div class="bannerView">
      <div class="rightView">
        <img
          style="width: 100%; height: 100%"
          src="../assets/tianshine/top4.jpg"
        />
      </div>
      <div class="leftView">
        <div class="leftBottmView" :style="{fontSize:leftText+'vh'}">
          使用AI识别技术，
          <br />
          通过机器视觉＋物联网视觉检测技术等手段，
          <br />
          对厨房环境及食品储存加工区域实行智能化改造，
          <br />
          根据食品安全法条例规定，
          <br />
          规范所有流程，使得整套操作清晣明了，
          <br />
          通过AI实时感测云中心，对食品环境进行实时检测，
          <br />
          建立多层级事先预警管理机制。
        </div>
      </div>
    </div>
    <div class="bannerView">
      <div class="leftView">
        <div class="leftBottmView" :style="{fontSize:leftText+'vh'}">
          电视大屏展示减轻操作人员繁琐工作，提升工作效率；

          <br />
          智能电子秤、人脸识别打卡测温仪、智能留样柜等设备，
          <br />
          台账数据真实简洁，避免人为修改食品安全操作信息，
          <br />
          实时图片留证防范食安操作风险。
          <br />
        </div>
      </div>
      <div class="rightView">
        <img
          style="width: 100%; height: 100%"
          src="../assets/tianshine/top2.jpg"
        />
      </div>
    </div>
    <div class="bannerView">
      <div class="rightView">
        <img
          style="width: 100%; height: 100%"
          src="../assets/tianshine/top7.jpg"
        />
      </div>
      <div class="leftView">
        <div class="leftBottmView" :style="{fontSize:leftText+'vh'}">
          账目明细，校，厨务公司，供应商对账清晰。
        </div>
      </div>
    </div>
    <div class="middle1">
      <span>这些行业可以用我们</span>
      <div class="middle1Top">
        <img class="middleImgView" src="../assets/tianshine/bottom1.png" />
        <img class="middleImgView" src="../assets/tianshine/bottom2.png" />
        <img class="middleImgView" src="../assets/tianshine/bottom3.png" />
      </div>
      <div class="middle1Top">
        <img class="middleImgView" src="../assets/tianshine/bottom4.png" />
        <img class="middleImgView" src="../assets/tianshine/bottom5.png" />
        <img class="middleImgView" src="../assets/tianshine/bottom6.png" />
      </div>
    </div>
    <div style="text-align: center;padding: 1vw;font-size: 1.5vw;">
      <span>他们正在使用我们</span>
    </div>
    
    <div class="bottomView">
      <div class="bottomImageView">
        <img class="ImageStyle" src="../assets/tianshine/bottom9.jpg" />
      </div>
      <div class="bottomImageView">
        <img class="ImageStyle" src="../assets/tianshine/bottom10.jpg" />
      </div>
    </div>
    <div class="middleView" style="margin-top: 2vh;">
      <span>现开启全国招商，欢迎咨询</span>
    </div>
    <div style="display: flex;flex-direction: row;width: 1/3vw;height: 20vh;">
      <div class="bottomItemView" :style="{fontSize:textBottom+'vh'}">
        <img src="../assets/homeIcon1.jpg" class="bottomIcon">
        <span style="color: #026eb7;">公司地址</span>
        <span>安徽省合肥市高新区同创科技园5栋5楼</span>
      </div>
      <div class="bottomItemView" :style="{fontSize:textBottom+'vh'}">
        <img src="../assets/homeIcon2.jpg" class="bottomIcon">
        <span style="color: #026eb7;">联系电话</span>
        <span>0551-63671869</span>
      </div>
      <div class="bottomItemView" :style="{fontSize:textBottom+'vh'}">
        <img src="../assets/homeIcon3.jpg" class="bottomIcon">
        <span style="color: #026eb7;">邮箱</span>
        <span>2645053571@qq.com</span>
      </div>
    </div>
    <div class="bottom1">
      安全更高效的食品安全服务
      <br>
      10年服务经验积累，9种安全保障，多种服务场景，优质服务值得您的信赖
    </div>
    <div class="bottom2">
      <div class="bottomText">
        安徽碧耕软件有限公司
        <br>
        地址：安徽省合肥市高新区同创科技园5栋5楼
        <br>
        邮箱：2845053571@qq.com
        <br>
        电话：0551-63671869
      </div>
      
      <div></div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "Tianshine",
  data(){
    return{
      leftText:3,
      textBottom:2
    }
  },
  mounted(){
      if (this.isMobile()) {
        this.leftText =1.1;
        this.textBottom = 1.5;
      }
    },
    methods:{
      goDetial(status){
        this.$router.push('/'+status, ()=>{}, ()=>{})  
      },
      isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
      }
    }
};
</script>
<style lang="less">
.content {
  display: flex;
  height: 100vh;
  flex-direction: column;
  .bannerView {
  display: flex;
  width: 100vw;
  height: auto;
}
}

.leftView {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  .leftTopView {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 1.8vw;
    // color: #567722;
  }
  .leftBottmView {
    
    padding: 2vh;
    
    width: auto;
    height: 100%;
    text-align: center;
  
    //font-size: 3vh;
  }
}
.rightView {
  display: flex;
  flex: 1;
  padding: 2vw;
}
.middle1 {
  display: flex;
  flex-direction: column;
  height: 30%;
  width: 100vw;
  padding: 2vw;

  text-align: center;
  font-size: 1.5vw;

  background-color: #f2f2f2;
  .middle1Top {
    display: flex;
    width: 100%;
    height: 50%;

    justify-content: space-between;
  }
  .middleImgView {
    width: 30%;
    height: 80%;
  }
}
.bottomView {
  height: 23%;
  width: 100vw;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  .bottomImageView {
    flex: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ImageStyle {
    width: 80%;
    height: 100%;
  }
  .middleView{
    display: flex;
    width: auto;
    height: 10%;
    align-items: center;
    justify-content: center;
    font-size: 6vh
    
  }
  .bottomItemView{
      margin-top: 2vh;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-color: #3894ff;
      
    }
    
}
.bottom1{
      padding: 2vh;
      font-size: 1.5vw;
      width: 100vw;
      height: 20vh;
      text-align: center;
      background-color: #333333;
      color: aliceblue;
    }
    .bottom2{
      flex-direction: row;
      padding: 2vh;
      font-size: 1.5vw;
      width: 100vw;
      height: 20vh;
      text-align: center;
      background-color: #2d2d2d;
      color: aliceblue;
      .bottomText{
        color: #f2f2f2;
        font-size: .8vw;
        text-align: center;
      }
    }
</style>
