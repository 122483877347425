<template>


  <div id = "app" class="fullSceen">
      <div style="width: 100%;height: 10%;">
    
      <Header></Header>
      </div>
      <div style="width: 100%;height: 90%;">
        <router-view></router-view>
      </div>


  </div>


</template>


<script>


import Header from './components/Header.vue'


export default {


  name: 'App',
  components: {
    Header
  },
  


}


</script>
<style lang="less">
  .fullSceen {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    margin-left: 0%;
    
  }
</style>
