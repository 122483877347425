<template>
    <div class="head">
      <div class="topLeft" :style="{fontSize:rightText+'vh'}">
        <span>安徽碧根软件有限公司</span>
      </div>
      <div class="topRight" :style="{fontSize:leftText+'vh'}">
        <router-link to="/" class="link" style="padding-left: 2%;">
          <span>首页</span>
        </router-link>
        <router-link to="/about" class="link">
          <span style="font-weight: bold;">关于我们</span>
        </router-link>
        
      </div>
    </div>
</template>
<script>
export default{
    name: 'Header',
    data(){
      return{
        showDropdown:false,
        options:[
          {id:1,name:'天食安平台'},
          {id:2,name:'大食慧平台'}
        ],
        rightText:6,
        leftText:4
      }
     
    },
    mounted(){
      if (this.isMobile()) {
        this.rightText = 2.2;
        this.leftText = 1.5;
        
        // 移动端逻辑
      } 
    },
    methods:{
      toggleDropdown(){
        this.showDropdown = !this.showDropdown;
      },
      isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
      }
    }
   
   
}
</script>
<style lang="less">
  .head{
   display: flex; 
   width: 100vw;
   height: 100%;
   flex-direction: row;
   
   }
  .topLeft{
    flex: 4;
    height: 100%;
    width: auto;
    display: flex;
    padding-left: 5%;
   
    color: #3894FF;
    font-size: 6vh;
    align-items: center;
    justify-content: center;
   
    
  }
  .topRight{
  
    flex: 3;
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    font-size: 3vh;
    margin-right: 5%;
   
  }
  .link{
    color: black;
    text-decoration: none;
    margin-left: 2%;
   
  }
</style>